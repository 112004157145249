import React, {useEffect, useState} from 'react';
import {withUser} from "../../../../context/UserContext";
import './AssessmentsAdminV2.css'
import AdminHeader from "../AdminHeader";
import {FileAddOutlined} from "@ant-design/icons";
import {Rest} from "../../../../util/rest";
import {toast} from "react-toastify";
import AssessmentTemplateEditorV2 from "./AssessmentTemplateEditorV2";
import AssessmentTemplateV2 from "./AssessmentTemplateV2";
import {Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";

const AssessmentAdminV2 = (props) => {
    const [filters, setFilters] = useState([{filterTitle: "Draft", filter: "DRAFT", checked: true},
                                               {filterTitle: "Published", filter: "PUBLISHED", checked: true},
                                               {filterTitle: "Inactive", filter: "INACTIVE", checked: false},
                                           ]);
    const [filterString, setFilterString] = useState("DRAFT,PUBLISHED");
    const [pageNumber, setPageNumber] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [templates, setTemplates] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [editMode, setEditMode] = useState(false);
    const [deleteMode, setDeleteMode] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [selectedDeleteTemplate, setSelectedDeleteTemplate] = useState(null);
    const [selectedTemplate, setSelectedTemplate] = useState(null);

    useEffect(() => {
        getTemplates();
    }, []);

    useEffect(() => {
        getTemplates();
    }, [pageNumber, searchTerm, filterString]);


    useEffect(() => {
        if(selectedTemplate != null) {
            setEditMode(true);
        }
    }, [selectedTemplate])

    function getTemplates() {
        setIsLoading(true);
        setTemplates([]);

        Rest.authFetch(props.user, `/rest/assessment/searchTemplates/${searchTerm}?page=${pageNumber - 1}&filters=${filterString}`)
                .then(response => {
                    if(response.status === 401 || response.status === 403) {
                        toast.error("Unauthorized!");
                    }
                    else {
                        setTemplates(response.content);
                        setPageNumber(response.number + 1);
                        setTotalPages(response.totalPages);
                    }
                })
                .catch(err => {
                    toast.error("System Error loading Templates");
                    console.debug(err);

                })
                .finally(() => setIsLoading(false));
    }

    function handleFilterChange(filters) {
        setFilters(filters);
        setFilterString(filters.filter(f => f.checked).map((f) => {
            return f.filter;
        }).join(","));
    }

    function handlePageChange(event, value) {
        setPageNumber(value);
    }

    function handleDeleteTemplate(template) {
        if(template && template.id > 0) {
            setSelectedDeleteTemplate(template);
            setDeleteMode(true);
        }
    }

    function deleteTemplate() {
        if(selectedDeleteTemplate) {
            setIsDeleting(true);
            Rest.authFetch(props.user, "/rest/assessment/templates/" + selectedDeleteTemplate.id, {
                method: 'DELETE'
            })
                    .then(response => {
                        if(response.status === 401 || response.status === 403) {
                            toast.error("Unauthorized!")
                        }
                        else {
                            getTemplates()
                        }
                    })
                    .catch(err => {
                        console.log(err);
                    })
                    .finally(() => {
                        setDeleteMode(false);
                        setSelectedDeleteTemplate(null);
                        setIsDeleting(false);
                    });
        }
        else
            setDeleteMode(false);

    }

    function addNewTemplate() {
        //TODO: implement
        setSelectedTemplate({
                                id: 0,
                                name: "New Assessment Template",
                                description: "",
                                instructions: "",
                                questions: [],
                                groups: [],
                                status: "DRAFT"
                            })
        setEditMode(true);
    }

    return (
            <>
                {!editMode &&
                 <div className={"assessment-templates"}>
                     <AdminHeader
                             label={"Assessment Templates"}
                             onChange={(event) => setSearchTerm(event.target.value)}
                             onClickIcon={addNewTemplate}
                             icon={<FileAddOutlined title="Add Template"/>}
                             filters={filters}
                             onFilterChanged={handleFilterChange}
                             onPageChange={handlePageChange}
                             pageNumber={pageNumber}
                             totalPages={totalPages}
                     />
                     {isLoading &&
                      <div className="text-center my-5">
                          <button className="btn btn-primary-color btn-lg rounded shadow-sm" type="button" disabled>
                              <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"/>
                              Loading...
                          </button>
                      </div>
                     }

                     <div className={"templates-container"}>
                         {
                             templates.map((template) =>
                                                   <AssessmentTemplateV2 key={template.id}
                                                                         template={template}
                                                                         onEdit={setSelectedTemplate}
                                                                         onDelete={handleDeleteTemplate}
                                                                         readOnly={template && template.status !== "DRAFT"}
                                                           // onClone={this.cloneTemplate}
                                                   />)
                         }
                     </div>
                 </div>}
                {editMode &&
                 <AssessmentTemplateEditorV2
                       
                 />
                }

                <Dialog open={deleteMode}
                        onClose={() => {
                            setDeleteMode(false);
                            setSelectedDeleteTemplate(null)
                        }}
                        className={"delete-dialog"}>
                    <DialogTitle title="Delete Assessment Template"
                                 classes={{root: "delete-dialog-header"}}>Caution</DialogTitle>
                    <DialogContent classes={{root: "delete-dialog-content"}}>You're about to delete the following
                        assessment template:<br/><b>{selectedDeleteTemplate ? selectedDeleteTemplate.name : ""}</b><br/>This
                        action is irreversible. Do you
                        want to continue?
                        {isDeleting && <div className={"wait"}><CircularProgress/></div>}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => {
                            setDeleteMode(false);
                            setSelectedDeleteTemplate(null);
                        }}
                                autoFocus
                                style={{margin: '0 5px', fontWeight: 'bolder'}}
                                className="editorButton"
                                variant="contained"> No </Button>
                        <Button onClick={() => deleteTemplate()}
                                style={{margin: '0 5px'}}
                                className="editorButton"
                                variant="contained"> Yes </Button>
                    </DialogActions>
                </Dialog>
            </>
    );
}

export default withUser(AssessmentAdminV2);