import React, {Component} from 'react';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import '../agenda/AgendaItem.css';
import {DeleteOutlined, LinkOutlined} from "@ant-design/icons";
import TextArea from "../../assessment/inputtypes/TextArea";
import AssessmentAnswersTable from "./AssessmentAnswersTable";
import {withUser} from "../../../../context/UserContext";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import {Rest} from "../../../../util/rest";
import {toast} from "react-toastify";
import Autocomplete from "@mui/material/Autocomplete";

const inputLabelProps = {
    classes: {
        root: 'textField',
        focused: 'focused'
    },
};

class AssessmentQuestion extends Component {
    constructor(props) {
        super(props);
        this.state = {
            question: this.props.question
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleUpdateAnswers = this.handleUpdateAnswers.bind(this)
        this.handleMouseLeave = this.handleMouseLeave.bind(this)
        this.handleDelete = this.handleDelete.bind(this)
        this.handleDependencySelected = this.handleDependencySelected.bind(this)
    };

    componentDidMount() {
        if(this.state.question.id < 1) {
            this.save();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.question !== this.props.question) {
            this.setState({question: this.props.question})
        }
    }

    render() {
        return (
                <div className={"d-flex justify-content-between"} onMouseLeave={this.handleMouseLeave}>
                    <div className="my-auto mx-2">{this.props.index + 1}.</div>
                    <div className="container-fluid agendaItem shadow-sm border">
                        <div className="row">
                            <div className="col-md-3 my-auto">
                                <TextArea
                                        autoFocus
                                        value={this.state.question.question}
                                        label={"Question"}
                                        onChange={this.handleChange}
                                        name={"question"}
                                        rows={9}
                                        readOnly={this.props.readOnly}
                                />
                            </div>
                            <div className="col-md-8">
                                <div className={"row mb-3"}>
                                    <div className={"col-md-4"}>
                                        <FormControl variant="outlined" fullWidth>
                                            <InputLabel>Question Type</InputLabel>
                                            <Select
                                                    onChange={this.handleChange}
                                                    name={"questionType"}
                                                    value={this.state.question.questionType}
                                                    className={"textField bg-white"}
                                                    disabled={this.props.readOnly}
                                            >
                                                <MenuItem value={"FREE_TEXT"}>Free Text</MenuItem>
                                                <MenuItem value={"SELECTION"}>Selection</MenuItem>
                                                <MenuItem value={"MULTIPLE_SELECTION"}>Multiple Selection</MenuItem>
                                                <MenuItem value={"RANGE"}>Range</MenuItem>
                                                <MenuItem value={"NUMERIC_VALUE"}>Numeric Value</MenuItem>
                                                <MenuItem value={"DATE"}>Date</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className="col-md-2">
                                        <TextField
                                                label="Weight"
                                                variant="outlined"
                                                name={"weight"}
                                                type="number"
                                                onChange={this.handleChange}
                                                value={this.state.question.weight}
                                                InputLabelProps={inputLabelProps}
                                                className="textField"
                                                inputProps={{readOnly: this.props.readOnly}}
                                        />
                                    </div>
                                    {(this.state.question.questionType === "RANGE" || this.state.question.questionType === "NUMERIC_VALUE") &&
                                     <>
                                         <div className="col-md-2">
                                             <TextField
                                                     label="Min"
                                                     type="number"
                                                     name={"min"}
                                                     onChange={this.handleChange}
                                                     value={this.state.question.min}
                                                     defaultValue={1}
                                                     InputLabelProps={inputLabelProps}
                                                     className="textField"
                                                     variant="outlined"
                                                     inputProps={{readOnly: this.props.readOnly}}
                                             />
                                         </div>
                                         <div className="col-md-2">
                                             <TextField
                                                     label="Max"
                                                     type="number"
                                                     name={"max"}
                                                     onChange={this.handleChange}
                                                     value={this.state.question.max}
                                                     defaultValue={5}
                                                     InputLabelProps={inputLabelProps}
                                                     className="textField"
                                                     variant="outlined"
                                                     inputProps={{readOnly: this.props.readOnly}}
                                             />
                                         </div>
                                         <div className="col-md-2">
                                             <TextField
                                                     label="Step"
                                                     type="number"
                                                     name={"steps"}
                                                     onChange={this.handleChange}
                                                     defaultValue={1}
                                                     InputLabelProps={inputLabelProps}
                                                     className="textField"
                                                     variant="outlined"
                                                     inputProps={{readOnly: this.props.readOnly}}
                                             />
                                         </div>
                                     </>
                                    }
                                </div>
                                <div className={"row"}>
                                    <div className={"col-md-7"}>
                                        {(this.state.question.questionType === "SELECTION" || this.state.question.questionType === "MULTIPLE_SELECTION") &&
                                         <AssessmentAnswersTable
                                                 answers={this.state.question.answers}
                                                 onUpdateAnswers={this.handleUpdateAnswers}
                                                 questionId={this.state.question.id}
                                                 readOnly={this.props.readOnly}
                                         />
                                        }
                                    </div>
                                    <div className={"col-md-5"}>
                                        <div className={"row"}>
                                            <div className={"col-md-12 mt-4"}>
                                                <Autocomplete
                                                        multiple
                                                        name={"dependencies"}
                                                        options={!this.props.options ? [] : this.props.options.filter(q => q.id !== this.state.question.id)}
                                                        getOptionLabel={(option) => option.question}
                                                        
                                                        getOptionSelected={(option, value) => {
                                                            return option?.id === value?.id || option?.question.toLowerCase() === value?.question.toLowerCase()
                                                        }}

                                                        value={this.state.question.dependencies.map((d) => ({
                                                            id: d.assessmentTemplateDependentQuestionId,
                                                            question: this.props.options.find(o => o.id === d.assessmentTemplateDependentQuestionId).question
                                                        }))}
                                                        filterSelectedOptions
                                                        onChange={this.handleDependencySelected}
                                                        renderInput={(params) => (
                                                                <TextField
                                                                        {...params}
                                                                        variant="outlined"
                                                                        label="Dependencies"
                                                                        placeholder="Search"
                                                                />
                                                        )}
                                                        disabled={this.props.readOnly}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={"col-md-1 text-center"}>
                                <div className={"row mb-5"}>
                                    <FormControlLabel
                                            classes={{root: 'FormControlLabel'}}
                                            label={"Required"}
                                            control={
                                                <Checkbox
                                                        classes={{root: 'Checkbox', checked: "checked"}}
                                                        name={"required"}
                                                        checked={this.state.question.required}
                                                        onChange={this.handleChange}
                                                        disabled={this.props.readOnly}
                                                />
                                            }
                                    />
                                </div>
                                {!this.props.readOnly &&
                                 <>
                                     <div className="edit row">
                                         <DeleteOutlined className={"mx-auto"} title="Delete Question"
                                                         onClick={this.handleDelete}/>
                                     </div>
                                     <div className="edit row">
                                         <LinkOutlined type="link" className={"mx-auto"} title={"Dependencies"}/>
                                     </div>
                                 </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
        );
    }

    handleMouseLeave() {
        if(this.props.question !== this.state.question) {
            this.save()
        }
    }

    handleUpdateAnswers(answers) {
        this.setState((state) => ({
            question: {
                ...state.question,
                answers: answers
            }
        }), () => this.save())
    }

    handleDependencySelected(event, questions) {
        if(questions) {
            const dependencies = questions.map((q) => ({
                id: 0,
                assessmentTemplateQuestionId: this.state.question.id,
                assessmentTemplateDependentQuestionId: q.id,
                assessmentTemplateAnswerId: null
            }))
            this.setState((prevState) => ({
                question: {
                    ...prevState.question,
                    dependencies: dependencies
                }
            }))
        }
    }

    handleChange(event) {
        let {name, value, type} = event.target;

        if(type === "number") {
            value = parseInt(value)
        }
        else if(type === "checkbox") {
            value = !this.state.question.required
        }

        this.setState((prevState) => ({
            question: {
                ...prevState.question,
                [name]: value.toString().length >= 1 && value
            }
        }));
    }

    handleDelete() {
        const originalId = this.state.question.id;
        Rest.authFetch(this.props.user, `/rest/assessment/templates/question/${this.state.question.id}`, {
            method: "DELETE"
        })
                .then(response => {
                    if(response.status === 401 || response.status === 403) {
                        toast.error("Unauthorized user!")
                    }
                    else if(this.props.onDelete) {
                        this.props.onDelete(originalId);
                    }
                });
    }

    saveDependencies(dependencies) {
        Rest.authFetch(this.props.user, "/rest/assessment/templates/question/dependencies", {
            method: "PUT",
            body: JSON.stringify(dependencies)
        })
                .then(response => {
                    if(response.status === 403) {
                        toast.error("Unauthorized user!");
                    }
                    else if(response) {
                        this.setState((state) => ({
                            question: {
                                ...state.question,
                                dependencies: response
                            }
                        }));
                        // this.props.onChange()
                    }
                    else {
                        toast.error("Error saving Dependency");
                    }
                });
    }

    save = async () => {
        if(!this.props.readOnly) {
            const originalId = this.state.question.id;
            const url = "/rest/assessment/templates/question";
            const data = {
                method: "PUT",
                body: JSON.stringify(this.state.question)
            };
            const response = await Rest.authFetch(this.props.user, url, data)
                    .catch(err => {
                        console.log(err)
                    });
            if(response) {
                this.setState({
                                  question: response
                              });
                if(this.props.onChange)
                    this.props.onChange(originalId, response);
            }
        }
    }
}

export default withUser(AssessmentQuestion);