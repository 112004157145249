import React, {useState} from 'react';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import {LockOutlined, UserOutlined} from "@ant-design/icons";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import {Navigate} from "react-router-dom";
import {Auth} from "../../util/auth";
import {toast} from 'react-toastify';
import {withUser} from "../../context/UserContext";
import {withNavigate} from "../../util/NavigateHO"
const inputLabelProps = {
    classes: {
        root: 'textField',
        focused: 'focused'
    },
};
const Login = (props) => {

    const [open, setOpen] = useState(true);
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [shake, setShake] = useState(false);
    
    const handleClose = (event, reason) => {
        if(reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
            // Set 'open' to false, however you would do that with your particular code.
            setOpen(false);
        }
    }

    const handleInputChange = (event) => {
        if(event.target.name==="username"){
            setUsername(event.target.value);
        }
        else if(event.target.name==="password"){
            setPassword(event.target.value);
        }
    }

    const handleKeyDown = (event) => {
        if(event.key === "Enter") {
            event.preventDefault();
            login()
        }
    }

    const login = () => {
        if(username && password) {
            Auth.login(username, password)
                    .then((authResult) => {
                        if(authResult.authenticated) {
                            setOpen(false)
                            props.setUser(authResult.user);
                            props.navigate(-1);
                        }
                        else {
                            toast.error("Wrong username or password!");
                            handleShake();
                        }
                    });
        }
    }

    const handleShake = () => {
        // Trigger shaking effect
        setShake(true);

        // Stop shaking after a short duration
        setTimeout(() => setShake(false), 600); // Adjust duration of the shake effect
    };

    return (
            <Dialog open={open}
                    fullWidth={true}
                    maxWidth={"xs"}
                    onClose={handleClose}
                    aria-labelledby="form-dialog-title"
                    className={"login-dialog"}
                    PaperProps={{
                        style: {
                            transform: shake ? "translateX(0)" : undefined, // Reset to normal position after shake ends
                            animation: shake
                                       ? "shake-animation 0.3s ease-in-out" // Trigger custom shake animation
                                       : undefined,
                        },
                    }}
            >
                <DialogTitle classes={{root: "editorHeader"}} id="form-dialog-title">Smart PCMH</DialogTitle>
                <DialogContent className="container-fluid pcmh-profile-editor">
                    <fieldset>
                        <div className="row">
                            <div className="col-md-12">
                                <TextField
                                        name="username"
                                        type="text"
                                        placeholder="Username"
                                        title="Username"
                                        required={true}
                                        autoFocus={true}
                                        autoComplete="username"
                                        formEncType="application/json"
                                        // onKeyUp={handleInputChange}
                                        onChange={handleInputChange}
                                        margin="dense"
                                        label="Username"
                                        variant="outlined"
                                        InputLabelProps={inputLabelProps}
                                        className="textField" fullWidth
                                        InputProps={{
                                            startAdornment: (
                                                    <InputAdornment position="start">
                                                        <UserOutlined/>
                                                    </InputAdornment>
                                            ),
                                        }}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <TextField name="password"
                                           required={true}
                                           placeholder="Password"
                                           title="Password"
                                           type="password"
                                           formEncType="application/json"
                                           onChange={handleInputChange}
                                           // onKeyUp={handleInputChange}
                                           onKeyDown={handleKeyDown}
                                           margin="dense" label="Password" variant="outlined"
                                           InputLabelProps={inputLabelProps} 
                                           className="textField" fullWidth
                                           InputProps={{
                                               startAdornment: (
                                                       <InputAdornment position="start">
                                                           <LockOutlined/>
                                                       </InputAdornment>
                                               ),
                                           }}
                                />

                            </div>
                        </div>
                        <div className="row">
                        </div>

                    </fieldset>

                </DialogContent>
                <div className="editorFooter"
                     style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                    <Button
                            style={{color: 'forestgreen'}}
                            onClick={() => <Navigate to="/reset-password"/>}
                    >Forgot Password?
                    </Button>
                    <DialogActions>

                        <Button onClick={login} className="editorButton"
                                disabled={!username || !password}
                                variant="contained">Login</Button>

                    </DialogActions>
                </div>
            </Dialog>
    );
};

export default withNavigate(withUser(Login));