import './AssessmentsHeader.css'
import React from "react";


export function CellExpanderFormatter({tabIndex, expanded, onCellExpand}) {

    function handleKeyDown(e) {
        if(e.key === ' ' || e.key === 'Enter') {
            e.preventDefault();
            onCellExpand();
        }
    }

    return (
            <div onClick={onCellExpand} onKeyDown={handleKeyDown} className="expander">
                <span tabIndex={tabIndex}>{expanded ? '\u25BC' : '\u25B6'}</span>
            </div>
    );
}