import React, {useEffect, useState} from 'react';
import Icon, {CaretDownOutlined, CaretUpOutlined, DeleteOutlined, EditOutlined} from "@ant-design/icons";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import TextArea from "../../assessment/inputtypes/TextArea";
import AssessmentQuestion from "./AssessmentQuestion";
import {withUser} from "../../../../context/UserContext";
import {Rest} from "../../../../util/rest";
import toast from "../../../../util/toast";
import {useLegacyState} from "../../../../util/customHooks";
import {Draggable, Droppable} from "react-beautiful-dnd";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";

const AddQuestionSVG = () => (
        <svg width="23px" height="18px" viewBox="0 0 23 18" xmlns="http://www.w3.org/2000/svg">
            <defs/>
            <g id="add-question-svg" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <path d="M7.96484375,13.0078125 C7.97265625,12.3828125 8.0234375,11.921875 8.1171875,11.625 C8.265625,11.15625 8.56640625,10.7460938 9.01953125,10.3945312 L9.01953125,10.3945312 L9.8515625,9.75 C10.6953125,9.09375 11.265625,8.5546875 11.5625,8.1328125 C12.0703125,7.4375 12.3242188,6.58203125 12.3242188,5.56640625 C12.3242188,3.91015625 11.7402344,2.66015625 10.5722656,1.81640625 C9.40429688,0.97265625 7.9375,0.55078125 6.171875,0.55078125 C4.828125,0.55078125 3.6953125,0.84765625 2.7734375,1.44140625 C1.3125,2.37109375 0.53515625,3.94921875 0.44140625,6.17578125 L0.44140625,6.17578125 L3.828125,6.17578125 C3.828125,5.52734375 4.01757812,4.90234375 4.39648438,4.30078125 C4.77539062,3.69921875 5.41796875,3.3984375 6.32421875,3.3984375 C7.24609375,3.3984375 7.88085938,3.64257812 8.22851562,4.13085938 C8.57617188,4.61914062 8.75,5.16015625 8.75,5.75390625 C8.75,6.26953125 8.59375,6.7421875 8.28125,7.171875 C8.109375,7.421875 7.8828125,7.65234375 7.6015625,7.86328125 L7.6015625,7.86328125 L6.74609375,8.53125 C5.90234375,9.1875 5.37890625,9.76757812 5.17578125,10.2714844 C4.97265625,10.7753906 4.84765625,11.6875 4.80078125,13.0078125 L4.80078125,13.0078125 L7.96484375,13.0078125 Z M8.2109375,18 L8.2109375,14.6132812 L4.71875,14.6132812 L4.71875,18 L8.2109375,18 Z M19.4882812,18 L19.4882812,14.78125 L22.6914062,14.78125 L22.6914062,12.7421875 L19.4882812,12.7421875 L19.4882812,9.5390625 L17.4335938,9.5390625 L17.4335938,12.7421875 L14.2304688,12.7421875 L14.2304688,14.78125 L17.4335938,14.78125 L17.4335938,18 L19.4882812,18 Z"
                      id="?+" fill="#535353" fillRule="nonzero"/>
            </g>
        </svg>
);

const inputLabelProps = {
    classes: {
        root: 'textField',
        focused: 'focused'
    },
};

function AssessmentGroup(props) {

    const [group, setGroup] = useLegacyState(props.group);
    const [editGroup, setEditGroup] = useState(true);
    const [showQuestions, setShowQuestions] = useState(true);
    const [newGroup, setNewGroup] = useState(props.group ? props.group.id === 0 : false);
    const [displayInfo, setDisplayInfo] = useState(props.group.displayInfo);
    const [aboutToDelete, setAboutToDelete] = useState(false);

    useEffect(() => {
        if(props.group && props.group.id === 0) {
            save();
            setEditGroup(true);
        }
    }, [props.group]);

    useEffect(() => {
        if(group.displayInfo !== displayInfo) {
            setGroup({displayInfo: displayInfo})
            save();
        }
    }, [displayInfo]);

    useEffect(() => {
        if(props.onGroupChange) {
            props.onGroupChange(newGroup && group.id > 0 ? 0 : group.id, group);
        }
        if(newGroup && group.id > 0)
            setNewGroup(false);
    }, [group])

    function deleteGroup() {

        const originalId = group.id;
        Rest.authFetch(props.user, `/rest/assessment/templates/group/${group.id}`, {
            method: "DELETE"
        })
                .then(response => {
                    if(response.status === 401 || response.status === 403) {
                        toast.error("Unauthorized!")
                    }
                    else if(props.onDelete) {
                        props.onDelete(originalId);
                        setAboutToDelete(false);
                    }
                });
    }

    function addQuestion() {
        const newQuestion = {
            id: 0,
            templateId: props.template.id,
            question: "",
            questionType: "FREE_TEXT",
            order: group.questions.length + 1,
            required: true,
            weight: 0,
            min: 0,
            max: 0,
            steps: 0,
            answers: [],
            dependencies: [],
            groupId: group.id
        }
        const questions = group.questions;
        questions.push(newQuestion)
        setGroup(group => ({...group, questions: questions}));
    }

    function save() {
        if(!props.readOnly)
            Rest.authFetch(props.user, "/rest/assessment/templates/group", {
                method: "PUT",
                body: JSON.stringify(group)
            })
                    .then(response => {
                        if(response) {
                            setGroup(response);
                        }
                        else {
                            toast.error("Error saving Group");
                        }
                    });
    }

    function onQuestionUpdate(originalId, question) {
        const questions = group.questions;
        const questionIndex = questions.findIndex(q => q.id === originalId);
        if(questionIndex >= 0) {
            questions[questionIndex] = question;
            setGroup({questions: questions})
        }
    }

    function onQuestionDelete(originalId) {
        const questions = group.questions;
        const questionIndex = questions.findIndex(q => q.id === originalId);
        if(questionIndex >= 0) {
            questions.splice(questionIndex, 1);
            setGroup({questions: questions})
        }
    }

    return (
            <>
                <div className={"groups"}>
                    <div className="groups-control">
                        <div className="row">
                            <div className='col-2 text-right offset-10'>
                                {!props.readOnly &&
                                 <>
                                     <EditOutlined className="icon" title="Edit Group info"
                                                   onClick={() => setEditGroup(!editGroup)}/>
                                     <DeleteOutlined className={"mx-auto icon"} title="Delete Group"
                                                     onClick={() => {setAboutToDelete(true)}}/>
                                     <span onClick={() => addQuestion()}><Icon component={AddQuestionSVG}
                                                                               className="icon"
                                                                               title="Add Question"/></span>
                                 </>
                                }
                                {showQuestions ?
                                 <CaretUpOutlined className="icon" onClick={() => {setShowQuestions(false)}}/> :
                                 <CaretDownOutlined className="icon" onClick={() => {setShowQuestions(true)}}/>
                                }
                            </div>
                        </div>

                        <div className="row p-1">
                            {editGroup ?
                             <>
                                 <div className="col-6">
                                     <TextField
                                             fullWidth
                                             margin="dense"
                                             name="groupName"
                                             defaultValue={group.name}
                                             onChange={(e) => setGroup({name: e.target.value})}
                                             label="Group name"
                                             InputLabelProps={inputLabelProps}
                                             className="textField"
                                             variant="outlined"
                                             onBlur={() => save()}


                                     />
                                 </div>
                                 <div className={"col-sm-2"}>
                                     <TextField
                                             fullWidth
                                             margin="dense"
                                             name="weight"
                                             defaultValue={group.weight}
                                             onChange={(e) => setGroup({weight: e.target.value})}
                                             label="Group Weight"
                                             type="number"
                                             InputLabelProps={inputLabelProps}
                                             className="textField"
                                             variant="outlined"
                                             onBlur={() => save()}


                                     />
                                 </div>
                                 <div className={"col-sm-2"}>
                                     <TextField
                                             fullWidth
                                             margin="dense"
                                             name="order"
                                             defaultValue={group.order}
                                             onChange={(e) => setGroup({order: e.target.value})}
                                             label="Group Order"
                                             type="number"
                                             InputLabelProps={inputLabelProps}
                                             className="textField"
                                             variant="outlined"
                                             onBlur={() => save()}
                                     />
                                 </div>

                             </>
                                       :
                             <div className={"col-11"}>
                                 {group.name}
                             </div>
                            }
                        </div>
                        {editGroup &&
                         <>
                             <div className="row p-1">
                                 <div className="col">
                                     <FormControlLabel
                                             classes={{root: 'FormControlLabel'}}
                                             label={"Display Group Instructions"}
                                             labelPlacement="start"
                                             control={
                                                 <Checkbox
                                                         classes={{root: 'Checkbox', checked: "checked"}}
                                                         name={"displayInfo"}
                                                         checked={displayInfo}
                                                         
                                                         onChange={(e) => {setDisplayInfo(e.target.checked);}}
                                                 />
                                             }
                                     />
                                 </div>
                             </div>

                             <div className="row p-1">
                                 <div className="col-md-6">
                                     <TextArea
                                             autofocus={false}
                                             label={"Group Description"}
                                             name={"description"}
                                             defaultValue={group.description}
                                             onChange={(e) => setGroup({description: e.target.value})}
                                             onBlur={() => save()}
                                     />
                                 </div>
                                 <div className="col-md-6">
                                     <TextArea
                                             autofocus={false}
                                             label={"Group Instructions"}
                                             name={"instructions"}
                                             defaultValue={group.instructions}
                                             onChange={(e) => setGroup({instructions: e.target.value})}
                                             onBlur={() => save()}
                                     />
                                 </div>
                             </div>


                         </>
                        }
                    </div>
                    <div className="questions">
                        {showQuestions &&
                         <Droppable droppableId={group.id.toString()}>
                             {(provided) =>
                                     <div ref={provided.innerRef}>
                                         {group.questions.sort((a, b) => (a.order > b.order) ? 1 : -1).map((question, index) => (
                                                 <Draggable key={question.id}
                                                            draggableId={question.id.toString()}
                                                            index={index}
                                                            isDragDisabled={props.readOnly}>
                                                     {(provided) =>
                                                             <div
                                                                     ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                 <AssessmentQuestion
                                                                         index={index}
                                                                         key={question.id}
                                                                         options={group.questions.map((q) => ({
                                                                             id: q.id,
                                                                             question: q.question
                                                                         }))}
                                                                         question={question}
                                                                         onChange={onQuestionUpdate}
                                                                         onDelete={onQuestionDelete}
                                                                         readOnly={props.readOnly}
                                                                 />
                                                             </div>
                                                     }
                                                 </Draggable>
                                         ))}
                                         {provided.placeholder}
                                     </div>
                             }
                         </Droppable>
                        }
                    </div>
                </div>
                <div>
                    <Dialog open={aboutToDelete} aria-labelledby="form-dialog-title"
                            // onClose={(event,reason)=>{
                            //     if(reason !== 'backdropClick' && reason !== 'escapeKeyDown')
                            //         setShowAlert(false);
                            // }}
                            disableBackdropClick
                            disableEscapeKeyDown
                            className="pcmh-content-uploader">
                        <DialogTitle title="Delete Group" classes={{root: "editorHeader"}}>Delete Group</DialogTitle>
                        <DialogContent>
                            <span className={"warning-text"}>Warning! Deleting group will delete all associated questions.</span>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => {setAboutToDelete(false);}} variant="contained"
                                    className="editor-button">Cancel</Button>
                            <Button onClick={() => {deleteGroup();}} variant="contained"
                                    className="editor-button">Delete</Button>
                        </DialogActions>
                    </Dialog>
                </div>
            </>);
}

export default withUser(AssessmentGroup);