import React, {useEffect, useState} from 'react';
import {withUser} from "../../../context/UserContext";
import './AssessmentsHeader.css';
import DataGrid from "react-data-grid";
import {Rest} from "../../../util/rest";
import {CellExpanderFormatter} from "./CellExpanderFormatter";
import AssignmentGrid from "./AssignementGrid";
import CircularProgress from "@mui/material/CircularProgress";
import {dateFormatter, timeStampFormatter} from "./Formatters";
import {DownloadOutlined} from "@ant-design/icons";
import {getFileName} from "../../../util/responseHandlers";
import {toast} from "react-toastify";

export const AssignmentContext = React.createContext(null);

function AssessmentsBatches(props) {

    const [batches, setBatches] = useState([]);
    const [batchSortColumn, setBatchSortColumn] = useState(null);
    const [batchSortDirection, setBatchSortDirection] = useState("NONE");
    const [loading, setLoading] = useState(false);
    const [assignmentsMap,setAssignmentsMap] = useState(new Map());
    const updateAssignments = (batchId, assignments) => {assignmentsMap.set(batchId,assignments)} 
    const handleBatchSort = (columnKey, direction) => {
        setBatchSortColumn(columnKey);
        setBatchSortDirection(direction);
        const sortedBatches = [...batches]
                .sort((a, b) => {
                    const aValue = columnKey === "assignable.name"
                                   ? a.assignable?.name
                                   : a[columnKey];
                    const bValue = columnKey === "assignable.name"
                                   ? b.assignable.name
                                   : b[columnKey]
                    if(aValue == null) return 1;
                    if(bValue == null) return -1;
                    return direction === "ASC"
                           ? String(aValue).localeCompare(String(bValue))
                           : String(bValue).localeCompare(String(aValue));
                });
        setBatches(sortedBatches);

    }
    const assignments = {assignments: assignmentsMap, updateAssignments};

    const batchColumns = [
        {
            key: "expanded", name: "", minWidth: 30, editable: true,
            width: 30,
            colSpan(args) {
                return args.type === 'ROW' && args.row.type === 'DETAIL' ? 6 : undefined;
            },
            cellClass(row) {
                return row.type === 'DETAIL' ? "sub-grid" : undefined;
            },
            renderCell({row, tabIndex, onRowChange}) {

                if(row.type === 'DETAIL') {
                    return <AssignmentContext.Provider value={assignments}><AssignmentGrid
                            batchId={row.parentId}/></AssignmentContext.Provider>;
                }
                const handleExpand = () => {
                    const updatedRow = {
                        ...row, expanded: !row.expanded
                    };
                    onRowChange(updatedRow);
                }

                return (
                        <CellExpanderFormatter
                                expanded={row.expanded}
                                tabIndex={tabIndex}
                                onCellExpand={handleExpand}

                        />
                );
            }
        },
        {key: "batchName", name: "Batch", editable: false},
        {
            key: "batchDate", name: "Creation Date", editable: false, renderCell(props) {
                return props.row.batchDate ? dateFormatter.format(new Date(props.row.batchDate)) : "";
            }
        },
        {
            key: "dateEffective", name: "Effective Date", editable: false, renderCell(props) {
                return props.row.dateEffective ? timeStampFormatter.format(new Date(props.row.dateEffective)) : "";
            }
        },
        {
            key: "dateExpires", name: "Expiration Date", editable: false,  renderCell(props) {
                return props.row.dateExpires ? timeStampFormatter.format(new Date(props.row.dateExpires)) : "";
            }
        },
        { key: "downloadBatch", name: "", editable: false, minWidth: "30px", renderCell({row, tabIndex, onRowChange}) {
                
            return <div className={"icon my-auto"} onClick={()=>download(row.id)}><DownloadOutlined/></div>;
            }}
    ];

   useEffect(() => {
        loadBatches();
    }, []);

    function download(batchId){
        setLoading(true)
        return Rest.authFetch(props.user, "/rest/assignment/batch/"+batchId+"/results")
                .then(response => {
                    if(response.status === 401 || response.status === 403)
                        toast.error("Unauthorized!")
                    else if(response.status === 500)
                        toast.error("Error generating download File");
                    else {
                        response.text().then(v => {
                            const url = window.URL.createObjectURL(new Blob([v]))
                            const link = document.createElement('a')
                            link.href = url
                            link.setAttribute('download', getFileName(response))
                            document.body.appendChild(link)
                            link.click()
                            link.remove()
                        })
                    }
                }).finally(() => setLoading(false));
    }
    
    function loadBatches() {
        Rest.authFetch(props.user, "/rest/assignment/assessments/batches")
                .then(response => {
                    if(response.status === 401 || response.status === 403)
                        toast.error("Unauthorized!")
                    else if(response.status === 500)
                        toast.error("Error getting batches");
                    else {
                        const bs = response.map((b) => {
                            return {
                                ...b,
                                expanded: false,
                                type: 'MASTER'
                            }
                        }).sort((a, b) => a.batchName.localeCompare(b.batchName));
                        setBatches(bs);
                    }
                });
    }

    function onRowsChange(updatedRows, {indexes}) {
        const updatedRow = updatedRows[indexes[0]];
        if(updatedRow && updatedRow.type === 'MASTER') {
            if(updatedRow.expanded) {
                updatedRows.splice(indexes[0] + 1, 0, {
                    type: 'DETAIL',
                    id: updatedRow.id + 100,
                    parentId: updatedRow.id
                });
            }
            else {
                updatedRows.splice(indexes[0] + 1, 1);
            }
            setBatches(updatedRows);
        }
    }

    function rowKeyGetter(row) {
        return row.id;
    }

    return (
            <div className="assessment-batches">
                {loading && <CircularProgress/>}
                <DataGrid className={"grid rdg-light"}
                          columns={batchColumns}
                          rows={batches}
                          rowKeyGetter={rowKeyGetter}
                          onRowsChange={onRowsChange}
                          sortColumns={batchSortColumn}
                          sortDirection={batchSortDirection}
                          
                          onSort={handleBatchSort}
                          headerRowHeight={45}
                          rowHeight={(row) => (row.type === 'DETAIL' ? 200 : 45)}
                        // onGridRowsUpdated={this.onGridRowsUpdated}
                          enableCellSelect={false}
                          onCellKeyDown={(_, event) => {
                              if(event.isDefaultPrevented()) {
                                  // skip parent grid keyboard navigation if nested grid handled it
                                  event.preventGridDefault();
                              }
                          }}
                />
            </div>

    );


}

export default withUser(AssessmentsBatches);