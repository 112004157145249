import {withUser} from "../../../../context/UserContext";


const AssessmentTemplateEditorV2 = (props) =>{
  
    
    return (<div/>)
    
} 

export default withUser(AssessmentTemplateEditorV2)