import {Rest} from "./rest";
import {Buffer} from "buffer";

const adminRegex = /HITPIP/;
const providerRegex = /ROLE_PROVIDER/;

export class Auth {

    static login(user, passwd) {

        return new Promise((resolve) => {
            Rest.rawFetch('/rest/auth', {
                headers: {
                    Authorization: 'Basic ' + Buffer.from(user + ':' + passwd).toString('base64')
                }

            }).then(response => {
                if(response.status === 401 || response.status === 403) {
                    resolve({authenticated: false});
                    return;
                }

                if(!response.ok) {
                    resolve({authenticated: false});
                    return;
                }
                return response.json();
            }).then(data => {
                resolve({
                            authenticated: true,
                            user: data
                        })
            })
        });
    }

    static logout() {
        resolve({
                    authenticated: false,
                    user: null
                })
    }

    static renewToken(user) {
        if(user) {
            return new Promise((resolve) => {
                Rest.rawFetch('/rest/auth', {
                    headers: {
                        Authorization: user.tokenType + user.accessToken
                    }
                }).then(response => {
                    if(response.status === 401 || response.status === 403) {
                        resolve({authenticated: false});
                        return; 
                    }

                    if(!response.ok) {
                        resolve({authenticated: false});
                        return;
                    }
                    return response.json()
                }).then(data => {
                    resolve({
                                authenticated: true,
                                user: data
                            })
                })
            });

        }
    }

    static getProvider(user) {
        return new Promise(resolve => {
            const userCopy = Object.assign({}, user);
            if(userCopy && userCopy.authorities && userCopy.authorities.find(it => it.authority.match(/PROVIDER/))) {
                Rest.getJson('/rest/account/provider')
                        .then(userProvider => {
                            userCopy.provider = userProvider;
                            resolve(userCopy);
                        });
            }
            else {
                resolve(userCopy);
            }
        });

    }

    static matchesAuthority(user, regex) {

        if(!user || !user.authorities || user.authorities.length < 1) {
            return false;
        }

        //test admin
        if(user.authorities.find(it => regex.test(it.authority))) {
            return true;
        }

    }

    static isAdminOrProviderProfile(user, providerId) {

        //test admin
        if(Auth.matchesAuthority(user, adminRegex)) {
            return true;
        }

        //test provider
        return user
               && user.provider
               && user.provider.id
               && user.provider.id === providerId
               && Auth.matchesAuthority(user, providerRegex);

    }
}