
export function getFileName(response) {
    let filename = "";
    const disposition = response.headers.get('Content-Disposition')
    if(disposition && disposition.indexOf('attachment') !== -1) {
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = filenameRegex.exec(disposition);
        if(matches != null && matches[1]) {
            filename = matches[1].replace(/['"]/g, '');
        }
        return filename;
    }
    return "filename.csv"
}